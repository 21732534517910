'use client';
import { paywallTypes } from '@haaretz/s-consts';
import React from 'react';

import type { PaywallType } from '@haaretz/s-types';

const PaywallTypeContext = React.createContext<PaywallType | null | undefined>(undefined);
const PaywallTypeSetter = React.createContext<((value: PaywallType) => void) | undefined>(
  undefined
);

export function PaywallTypeProvider({
  children,
  value: defaultPaywallType,
}: React.PropsWithChildren<{ value: PaywallType | null }>) {
  const [paywallType, setPaywallType] = React.useState<PaywallType | null>(defaultPaywallType);

  const paywallTypeSetter = React.useCallback((value: PaywallType) => {
    const validatedPaywallType = paywallTypes.includes(value) ? value : 'premium';

    setPaywallType(validatedPaywallType);
  }, []);

  return (
    <PaywallTypeContext.Provider value={paywallType}>
      <PaywallTypeSetter.Provider value={paywallTypeSetter}>{children}</PaywallTypeSetter.Provider>
    </PaywallTypeContext.Provider>
  );
}

export default function usePaywallType() {
  const context = React.useContext(PaywallTypeContext);

  if (context === undefined) {
    throw new Error('usePaywallType must be used within a PaywallTypeProvider');
  }

  return context;
}

export function useSetPaywallType() {
  const context = React.useContext(PaywallTypeSetter);

  if (context === undefined) {
    throw new Error('useSetPaywallType must be used within a PaywallTypeProvider');
  }

  return context;
}

export function usePaywallTypeAtom() {
  return [usePaywallType(), useSetPaywallType()] as const;
}
