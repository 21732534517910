'use client';

import { useRef, useEffect } from 'react';

/**
 * ATTENTION: Callback not called on navigation
 *
 * @remarks
 *
 * This hook should be used only in case of a need to run a function only once.
 * If needed to run a callback on navigation, use useEffect or usePathChanged instead.
 */
export default function useOnce(cb: () => void | (() => void), condition: boolean) {
  const ref = useRef(false);
  useEffect(() => {
    let result: undefined | ReturnType<typeof cb> = undefined;

    if (!ref.current && condition && typeof cb === 'function') {
      ref.current = true;
      result = cb();
    }

    return result;
  }, [condition, cb]);
}
