/**
 * NOTE: Since this file does not currently get transpiled, it must use syntax
 * that UglifyJS supports.
 */
export function getAppData() {
  const appData = window.__HTZ_CONFIG__;

  if (!appData) {
    throw new Error('No __HTZ_CONFIG__ detected.');
  }

  return appData;
}
