'use client';

import { pageTypes } from '@haaretz/s-consts';
import React from 'react';

import type { PageType } from '@haaretz/s-types';

const PageTypeContext = React.createContext<PageType | undefined>(undefined);
const PageTypeSetter = React.createContext<((value: PageType) => void) | undefined>(undefined);

export function PageTypeProvider({
  children,
  value: defaultPageType,
}: React.PropsWithChildren<{ value: PageType }>) {
  const [pageType, setPageType] = React.useState<PageType>(defaultPageType);

  const pageTypeSetter = React.useCallback((value: PageType) => {
    const validatedPageType = pageTypes.includes(value) ? value : 'Homepage';

    setPageType(validatedPageType);
  }, []);

  return (
    <PageTypeContext.Provider value={pageType}>
      <PageTypeSetter.Provider value={pageTypeSetter}>{children}</PageTypeSetter.Provider>
    </PageTypeContext.Provider>
  );
}

export default function usePageType() {
  const context = React.useContext(PageTypeContext);

  if (context === undefined) {
    throw new Error('usePageType must be used within a PageTypeProvider');
  }

  return context;
}

export function useSetPageType() {
  const context = React.useContext(PageTypeSetter);

  if (context === undefined) {
    throw new Error('useSetPageType must be used within a PageTypeProvider');
  }

  return context;
}

export function usePageTypeAtom() {
  return [usePageType(), useSetPageType()] as const;
}
