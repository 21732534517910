'use client';

import usePlatform from '@haaretz/s-atoms/platform';
import { appScopeName } from '@haaretz/s-consts';
import useOnce from '@haaretz/s-use-once';
import React from 'react';

import useDocumentEventListener from '../useDocumentEventListener';

/**
 * This hook is used to check if the native application has loaded the webview.
 * If used in the web browser, it will return true immediately.
 *
 * @param onLoad - A callback to be called when the native application has loaded the webview.
 */
export default function useLoadEvent(onLoad?: () => void) {
  const platform = usePlatform();

  const [isLoaded, setIsLoaded] = React.useState(
    /* istanbul ignore next */
    platform !== 'app' || false
  );

  const onLoadElement = React.useCallback(() => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  const loadElementCallCount =
    typeof window === 'undefined'
      ? /* istanbul ignore next */
        0
      : window[appScopeName]?.Iphone.callCounter.loadElement ?? 0;

  React.useEffect(() => {
    if (!isLoaded && loadElementCallCount > 0) {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, loadElementCallCount]);

  useOnce(
    () => {
      onLoad?.();
    },
    platform === 'app' && isLoaded && typeof onLoad === 'function'
  );

  useDocumentEventListener('loadElement', onLoadElement, false);

  return isLoaded;
}
