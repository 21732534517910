import selectStorage from '../utils/selectStorage';

interface SetItemArgs {
  key: string;
  value: string | number | object;
  errorMessage?: string;
  storage: 'local' | 'session';
}
export default function setItem({ key, storage, value, errorMessage }: SetItemArgs) {
  try {
    if (typeof window !== 'undefined') {
      selectStorage(storage).setItem(
        key,
        typeof value === 'string' ? value : JSON.stringify(value)
      );
    }
  } catch (error) {
    console.error(`${errorMessage ? `${errorMessage}: ` : ''}${(error as Error).message}`);
  }
}
