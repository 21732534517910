import browserStorageSetItem from '@haaretz/s-browser-storage-utils/setItem';
import isTeaserBiData from '@haaretz/s-type-predicates/isTeaserBiData';

import type { BiFunction } from './types';

export default function saveBiDataToSessionStorage(biData: Parameters<BiFunction>['0']) {
  if (isTeaserBiData(biData) && biData.teaser.origin) {
    browserStorageSetItem({
      key: 'listOrigin',
      value: biData.teaser.origin,
      storage: 'session',
      errorMessage: 'error writing recommendation type to session storage ',
    });
    browserStorageSetItem({
      key: 'articleId',
      value: biData.teaser.contentId,
      storage: 'session',
      errorMessage: 'error writing teaser article id to session storage ',
    });

    if (biData.testGroup) {
      browserStorageSetItem({
        key: 'ab_test_group',
        value: biData.testGroup,
        storage: 'session',
      });
    }

    if (biData.testName) {
      browserStorageSetItem({
        key: 'ab_test_name',
        value: biData.testName,
        storage: 'session',
      });
    }

    if (biData.testGroupLabel) {
      browserStorageSetItem({
        key: 'ab_test_group_label',
        value: biData.testGroupLabel,
        storage: 'session',
      });
    }
  }
}
