import * as Types from '@haaretz/s-fragments/Types';

export type MarketingBulletFragment = (
  { text?: string | null, title: string }
  & { __typename: 'MarketingBullet' }
);


export type MarketingBulletFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const MarketingBulletFragmentGql = `
    fragment MarketingBulletFragment on MarketingBullet {
  __typename
  text
  title
}
    `;