import * as Types from '@haaretz/s-fragments/Types';

export type ImageCropFragment = (
  { height: number, width: number, x: number, y: number }
  & { __typename: 'ImageCrop' }
);


export type ImageCropFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const ImageCropFragmentGql = `
    fragment ImageCropFragment on ImageCrop {
  __typename
  height
  width
  x
  y
}
    `;