'use client';
import useArticleId from '@haaretz/s-atoms/articleId';
import useAuthors from '@haaretz/s-atoms/authors';
import usePageType from '@haaretz/s-atoms/pageType';
import usePaywallType from '@haaretz/s-atoms/paywallType';
import usePlatform from '@haaretz/s-atoms/platform';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import useUser from '@haaretz/s-atoms/user';
import capitalize from '@haaretz/s-common-utils/capitalize';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import { siteAlias } from '@haaretz/s-consts';
import useBiPageType from '@haaretz/s-use-bi-page-type';
import usePpAbTest from '@haaretz/s-use-pp-ab-test';
import useSelectedOffer from '@haaretz/s-use-selected-offer';
import React from 'react';

import type { BiData } from '@haaretz/s-data-structure-types';

const noop = () => undefined;

export default function useCollectBiData(): BiData {
  const _window = typeof window !== 'undefined' ? window : undefined;
  const page_type = useBiPageType();

  const useSelectedOfferData = page_type === 'Promotions' ? useSelectedOffer : noop;
  const useAbTestData = page_type === 'Promotions' ? usePpAbTest : noop;

  const user = useUser('cookieValue');
  const selectedOfferData = useSelectedOfferData();
  const platform = usePlatform();
  const articleId = useArticleId();
  const renderingKind = useRenderingKind();
  const paywallType = usePaywallType();
  const authors = useAuthors();
  const pageType = usePageType();
  const abTestData = useAbTestData();

  const currentPageUrl = _window ? new URL(_window.document.URL) : undefined;
  const urlSearchParams = new URLSearchParams(_window?.location.search);
  const { anonymousId: anonymous_id, extendedUserType, userType } = user || {};

  // Converts the 'urlSearchParams' to Record<string, string>
  const urlParamsObj: Record<string, string> = Array.from(urlSearchParams.entries()).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {}
  );

  const {
    utm_medium,
    htm_medium,
    utm_content,
    htm_content,
    utm_source,
    htm_source,
    utm_campaign,
    htm_campaign,
    utm_segment,
    utm_type,
    htm_type,
  } = urlParamsObj;

  const memoizedData = React.useMemo(() => {
    const data = discardNullableValues({
      ab_test_group_label: abTestData?.testGroupLabel,
      ab_test_group: abTestData?.testGroup,
      ab_test_name: abTestData?.testName,
      action_id: noop(), // should pass by caller
      ad_block_state: noop(), // TODO: check if we need it
      additional_info: noop(), // should pass by caller
      anonymous_id,
      article_id: articleId,
      article_type: pageType.endsWith('Article') ? pageType : undefined,
      blocked: renderingKind === 'blocked',
      brand: siteAlias,
      campaign_details: noop(), // should pass by caller
      campaign_name: noop(), // should pass by caller
      darkmode:
        typeof document !== 'undefined'
          ? document.documentElement.classList.contains('darkMode')
          : undefined,
      feature_theme: noop(), // should pass by caller
      feature_type: noop(), // should pass by caller
      feature: noop(), // should pass by caller
      marketing_element: noop(),
      newsletter_email: noop(), // should pass by caller
      newsletter_segment_id: noop(), // should pass by caller
      newsletter_segment_name: noop(), // should pass by caller
      next_article_id: noop(), // should pass by caller
      next_article_list_id: noop(), // should pass by caller
      next_article_list_name: noop(), // should pass by caller
      next_article_noinlist: noop(), // should pass by caller
      next_article_noinpage: noop(), // should pass by caller
      next_article_teaser_id: noop(), // should pass by caller
      next_article_viewname: noop(), // should pass by caller
      page_type,
      paywall_status: paywallType,
      personal_feature: noop(), // should pass by caller
      platform: capitalize(platform),
      primary_section: noop(),
      prod_no: selectedOfferData?.productNumber,
      promotions_no: selectedOfferData?.promotionNumber,
      recommendation_type: noop(),
      refactor: true,
      referrer_type: _window?.document?.referrer,
      sale_code: selectedOfferData?.saleCode,
      scroll_height: noop(), // should pass by caller
      secondary_section: noop(),
      site: currentPageUrl?.origin,
      stage: noop(), // should pass by caller
      url: currentPageUrl?.href,
      user_type: extendedUserType || userType,
      useragent: _window?.navigator.userAgent,
      utm_campaign: utm_campaign || htm_campaign,
      utm_content: utm_content || htm_content,
      utm_medium: utm_medium || htm_medium,
      utm_segment,
      utm_source: utm_source || htm_source,
      utm_type: utm_type || htm_type,
      writer_name: authors?.map(author => author.name).toString(),
    });

    return data;
  }, [
    abTestData?.testGroupLabel,
    abTestData?.testGroup,
    abTestData?.testName,
    anonymous_id,
    articleId,
    pageType,
    renderingKind,
    page_type,
    paywallType,
    platform,
    selectedOfferData?.productNumber,
    selectedOfferData?.promotionNumber,
    selectedOfferData?.saleCode,
    _window?.document?.referrer,
    _window?.navigator.userAgent,
    currentPageUrl?.origin,
    currentPageUrl?.href,
    extendedUserType,
    userType,
    utm_campaign,
    htm_campaign,
    utm_content,
    htm_content,
    utm_medium,
    htm_medium,
    utm_segment,
    utm_source,
    htm_source,
    utm_type,
    htm_type,
    authors,
  ]);

  return memoizedData;
}
