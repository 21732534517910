'use client';

import { renderingKinds } from '@haaretz/s-consts';
import React from 'react';

import type { RenderingKind } from '@haaretz/s-types';

const RenderingKindContext = React.createContext<RenderingKind | undefined>(undefined);
const RenderingKindSetter = React.createContext<((value: RenderingKind) => void) | undefined>(
  undefined
);

export function RenderingKindProvider({
  children,
  value: defaultRenderingKind,
}: React.PropsWithChildren<{ value: RenderingKind }>) {
  const [renderingKind, setRenderingKind] = React.useState<RenderingKind>(defaultRenderingKind);

  const renderingKindSetter = React.useCallback((value: RenderingKind) => {
    if (typeof value === 'string' && value.trim() !== '') {
      setRenderingKind(renderingKinds.includes(value) ? value : 'blocked');
    }
  }, []);

  return (
    <RenderingKindContext.Provider value={renderingKind}>
      <RenderingKindSetter.Provider value={renderingKindSetter}>
        {children}
      </RenderingKindSetter.Provider>
    </RenderingKindContext.Provider>
  );
}

export default function useRenderingKind() {
  const context = React.useContext(RenderingKindContext);

  if (context === undefined) {
    throw new Error('useRenderingKind must be used within a RenderingKindProvider');
  }

  return context;
}

export function useSetRenderingKind() {
  const context = React.useContext(RenderingKindSetter);

  if (context === undefined) {
    throw new Error('useSetRenderingKind must be used within a RenderingKindProvider');
  }

  return context;
}

export function useRenderingKindAtom() {
  return [useRenderingKind(), useSetRenderingKind()] as const;
}
