'use client';

import React from 'react';

type EventKeys = keyof ApplicationCustomEventMap;
type AddEventListener<T extends EventKeys> =
  // @ts-expect-error - Only custom events are allowed
  typeof document.addEventListener<T>;

export default function useDocumentEventListener<T extends EventKeys>(
  ...args: Parameters<AddEventListener<T>>
) {
  const [eventName, listener, options] = args;

  React.useEffect(() => {
    document.addEventListener(eventName, listener, options);
    return () => {
      document.removeEventListener(eventName, listener, options);
    };
  }, [eventName, listener, options]);
}
