import isPodcastEpisode from '@haaretz/s-type-predicates/isPodcastEpisode';

import getRecommendationType from './getRecommendationType';
import saveBiDataToSessionStorage from './saveBiDataToSessionStorage';

import type { BiType } from './types';
import type {
  ABTeaserBiData,
  BiDataOverrides,
  PersonalTeaserBiData,
  TeaserBiActionType,
  TeaserBiData,
} from '@haaretz/s-data-structure-types';
import type { PodcastEpisodeFragment } from '@haaretz/s-fragments/PodcastEpisode';

export default function getTeaserBiData(args: TeaserBiActionType, biType: BiType): BiDataOverrides {
  const {
    campaign_name,
    contentId,
    index,
    kind,
    listStyle,
    teaser,
    testGroup,
    testGroupLabel,
    testName,
    title,
  } = args;

  biType === 'action' && saveBiDataToSessionStorage(args);

  let teaserBiData: TeaserBiData | PersonalTeaserBiData | ABTeaserBiData = {
    next_article_noinlist: index + 1,
    action_id: 109,
    next_article_viewname: listStyle,
    next_article_list_name: title ?? '',
    next_article_list_id: contentId,
    next_article_teaser_id: teaser.teaserId || undefined,
    next_article_id: teaser.contentId,
  };

  if (listStyle === 'Scruffy') {
    if (!campaign_name) {
      console.error('campaign_name is required for Scruffy listStyle');
    }

    teaserBiData = {
      ...teaserBiData,
      action_id: 153,
      feature: 'Web Games List',
      feature_type: 'Content',
      campaign_name,
    };
  }

  if (listStyle === 'Schoonhoven') {
    const teaserMedia = teaser.media as PodcastEpisodeFragment;
    if (!isPodcastEpisode(teaserMedia)) {
      console.error('media does not contain PodcastEpisode');
    }
    if (index === 0) {
      teaserBiData = {
        ...teaserBiData,
        feature: 'Player in teaser',
        writer_name: teaserMedia.channel?.channelName || undefined,
      };
    }
    teaserBiData = {
      ...teaserBiData,
      writer_name: teaserMedia.channel?.channelName || undefined,
    };
  }

  if (kind === 'personal' || kind === 'abTest') {
    teaserBiData = {
      ...teaserBiData,
      recommendation_type: getRecommendationType(args.teaser.origin),
      personal_feature: true,
    };
  }

  if (kind === 'abTest') {
    teaserBiData = {
      ...teaserBiData,
      ...(testGroup ? { ab_test_group: testGroup } : {}),
      ...(testName ? { ab_test_name: testName } : {}),
      ...(testGroupLabel ? { ab_test_group_label: testGroupLabel } : {}),
    };
  }

  return teaserBiData;
}
