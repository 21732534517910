import { MiscMq, DEFAULT_MEDIA_TYPE } from '../lib/consts';
import BREAKPOINTS from '../scale/breakpoints';

import type { FromMq, MediaQuery, MqArgs, NoAtRuleMediaQuery, UntilMq } from '../lib/consts';

/**
 * Get a media query string sans the opening `@media`. for use with `matchMedia`.
 *
 * @public
 */
export function getMatchMediaString({
  from,
  until,
  misc,
  type = DEFAULT_MEDIA_TYPE,
}: MqArgs): NoAtRuleMediaQuery {
  let miscMq: undefined | MiscMq;
  let fromMq: undefined | FromMq;
  let untilMq: undefined | UntilMq;

  if (misc != null) miscMq = BREAKPOINTS.misc[misc];
  if (from != null) {
    const minWidth = BREAKPOINTS.widthsInEm[from];
    fromMq = `(min-width:${minWidth}em)`;
  }
  if (until != null) {
    const maxWidth = BREAKPOINTS.untilWidthsInEm[until];
    untilMq = `(max-width:${maxWidth}em)`;
  }

  return `${type}${miscMq == null ? '' : ` and ${miscMq}`}${
    fromMq == null ? '' : ` and ${fromMq}`
  }${untilMq == null ? '' : ` and ${untilMq}`}` as NoAtRuleMediaQuery;
}

/**
 * Get a media query string
 *
 * @public
 */
export default function getMqString(mqOpts: MqArgs): MediaQuery {
  const mqString: MediaQuery = `@media ${getMatchMediaString(mqOpts)}`;
  return mqString;
}
