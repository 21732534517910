import { defaultUser } from '@haaretz/s-consts';
import cookieToMap from '@haaretz/s-cookie-utils/cookieToMap';
import isValidSsoMap from '@haaretz/s-cookie-utils/isValidSsoMap';

import type { CircularObjectType, HtzUser } from '@haaretz/s-types';
/**
 * Build and return a new user instance, based on the current cookie state
 * Note: this method should have no side effects, and can be run as many times as you need it to:
 * each call to buildUser will
 * return type of User
 */
export default function createUser(cookieVal: string, forceRefresh = false): HtzUser {
  // Step 1: searching for side effect to determine login state
  const cookieMap = cookieToMap(cookieVal, forceRefresh);

  const ssoMap = cookieMap?.userDetails as CircularObjectType<string, string>;
  const anonymousId = cookieMap.anonymousId;

  let userOptions: HtzUser = defaultUser;

  if (ssoMap && typeof ssoMap === 'object' && isValidSsoMap(ssoMap)) {
    // Valid SSO and User side effect found - recover it
    userOptions = {
      ...userOptions,
      ...ssoMap,
    };
  }

  /* istanbul ignore else */
  if (typeof anonymousId === 'string') {
    userOptions = {
      ...userOptions,
      anonymousId,
    };
  }

  /* istanbul ignore next */
  if (!Array.isArray(userOptions.products)) {
    userOptions = {
      ...userOptions,
      products: [],
    };
  }

  userOptions.isLoggedIn = !!userOptions.userMail || !!userOptions.university;

  return userOptions;
}
