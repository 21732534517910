import * as Types from '@haaretz/s-fragments/Types';

export type PurchaseToggleKickerFragment = (
  { billing: Types.BillingType, text?: string | null }
  & { __typename: 'PurchaseToggleKicker' }
);


export type PurchaseToggleKickerFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const PurchaseToggleKickerFragmentGql = `
    fragment PurchaseToggleKickerFragment on PurchaseToggleKicker {
  __typename
  billing
  text
}
    `;