import * as Types from '@haaretz/s-fragments/Types';

import { ImageCropFragmentGql } from '@haaretz/s-fragments/ImageCrop';
import { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';
export type ImageFragment = (
  { alt: string, caption?: string | null, contentId: string, credit?: string | null, link?: string | null, photographer?: string | null, type: Types.ImageType, url?: string | null, files: Array<(
    { height: number, path: string, width: number, crops: (
      { headline?: (
        ImageCropFragment
        & { __typename: 'ImageCrop' }
      ) | null, landscape?: (
        ImageCropFragment
        & { __typename: 'ImageCrop' }
      ) | null, regular?: (
        ImageCropFragment
        & { __typename: 'ImageCrop' }
      ) | null, square?: (
        ImageCropFragment
        & { __typename: 'ImageCrop' }
      ) | null, vertical?: (
        ImageCropFragment
        & { __typename: 'ImageCrop' }
      ) | null, wide?: (
        ImageCropFragment
        & { __typename: 'ImageCrop' }
      ) | null }
      & { __typename: 'ImageCrops' }
    ) }
    & { __typename: 'ImageViewModel_ImageFile' }
  )> }
  & { __typename: 'htz_image_Image' }
);


export type ImageFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const ImageFragmentGql = `
    fragment ImageFragment on htz_image_Image {
  __typename
  alt
  caption
  contentId
  credit
  files {
    __typename
    crops {
      __typename
      headline {
        ...ImageCropFragment
      }
      landscape {
        ...ImageCropFragment
      }
      regular {
        ...ImageCropFragment
      }
      square {
        ...ImageCropFragment
      }
      vertical {
        ...ImageCropFragment
      }
      wide {
        ...ImageCropFragment
      }
    }
    height
    path
    width
  }
  link
  photographer
  type
  url
}
    `;