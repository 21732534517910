/**
 * Named breakpoint
 *
 * @public
 */
const BREAKPOINTS = {
  /**
   * Named width breakpoints boundaries, in pixels
   * @public
   */
  widths: {
    s: 600,
    m: 768,
    l: 1024,
    xl: 1280,
    xxl: 1632,
  } as const,
  /**
   * Named width breakpoints inclusive boundaries.
   * Used for `(min-width)` media queries
   * @public
   */
  widthsInEm: {
    s: 37.5,
    m: 48,
    l: 64,
    xl: 80,
    xxl: 102,
  } as const,
  /**
   * Named width breakpoints exclusive boundaries.
   * Used for `(max-width)` media queries
   * @public
   */
  untilWidthsInEm: {
    s: 37.4375,
    m: 47.9375,
    l: 63.9375,
    xl: 79.9375,
    xxl: 101.9375,
  } as const,
  /**
   * Named media queries of miscellaneous features
   * @public
   */
  misc: {
    landscape: '(orientation:landscape)',
    portrait: '(orientation:portrait)',
    reducedMotion: '(prefers-reduced-motion)',
  } as const,
} as const;

export default BREAKPOINTS;
