'use client';

import * as React from 'react';

import useCollectBiData from './helpers/useCollectBiData';

import type { BiData } from '@haaretz/s-data-structure-types';

const BiDataContext = React.createContext<BiData | undefined>(undefined);

const noop = () => ({});

let useCollectBiDataOverride = useCollectBiData;

export function BiDataContextProvider({ children }: React.PropsWithChildren) {
  if (typeof window !== 'undefined' && window.__IS_STORYBOOK__) {
    useCollectBiDataOverride = noop;
  }

  const biData = useCollectBiDataOverride();

  return <BiDataContext.Provider value={biData}>{children}</BiDataContext.Provider>;
}

export default function useBiDataContext() {
  const context = React.useContext(BiDataContext);

  if (context === undefined) {
    throw new Error('useBiDataContext must be used within a BiDataContext.');
  }

  return context;
}
