import * as Types from '@haaretz/s-fragments/Types';

import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
export type CounterFragment = (
  { endDate?: number | null, buttonText?: string | null, buttonLink?: string | null, messageHeadline?: string | null, message?: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )> | null }
  & { __typename: 'PurchaseCounter' }
);


export type CounterFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const CounterFragmentGql = `
    fragment CounterFragment on PurchaseCounter {
  endDate
  message {
    ...HtmlNodeFragment
  }
  buttonText
  buttonLink
  messageHeadline
}
    `;