import { recommendationTypes } from '@haaretz/s-consts';

import type { CommonTeaserDataFragment } from '@haaretz/s-fragments/CommonTeaserData';

export default function getRecommendationType(origin?: string | null) {
  const recommendationType =
    (origin && isRecommendationType(origin) && recommendationTypes[origin]) || origin;

  return recommendationType ?? undefined;
}

function isRecommendationType(
  origin: string | null
): origin is NonNullable<CommonTeaserDataFragment['origin']> {
  if (!origin) return false;

  return origin in recommendationTypes;
}
