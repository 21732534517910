import { parse } from 'graphql/language/parser';

import type { DocumentNode } from 'graphql';

export default function parseQuery(query: string): DocumentNode | null {
  try {
    return parse(query);
  } catch (err) {
    console.error((err as Error).message);

    return null;
  }
}
