export default function b64DecodeUnicode(str: string) {
  const isServer = typeof document === 'undefined';

  if (isServer) {
    return Buffer.from(str, 'base64').toString('utf-8');
  }

  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );
}
