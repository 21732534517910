import * as Types from '@haaretz/s-fragments/Types';

import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
export type StepDataFragment = (
  { instructions?: string | null, messageIds?: string | null, messages?: Array<string> | null, name: Types.PurchasePageSteps, title?: string | null, mobileInstructions?: string | null, mobileTitle?: string | null, content?: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )> | null }
  & { __typename: 'StepData' }
);


export type StepDataFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const StepDataFragmentGql = `
    fragment StepDataFragment on StepData {
  __typename
  content {
    ...HtmlNodeFragment
  }
  instructions
  messageIds
  messages
  name
  title
  mobileInstructions
  mobileTitle
}
    `;