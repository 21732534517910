import config from '@haaretz/l-config';

import breakUrl from '../breakUrl';
import normalizeHostname from '../normalizeHostname';

import type {
  Env,
  HtzExternalURL,
  HtzExternalUrlPath,
  HtzHostname,
  HtzHostnameWithSubdomain,
} from '@haaretz/s-types';
const hostnameRegex = /(?:.+)?((?:\.)?(?:haaretz|themarker)(?:\.co\.il|\.com))/;

export default function getHtzHostname(
  url: HtzExternalURL<Env, HtzExternalUrlPath> | HtzHostnameWithSubdomain | HtzHostname | URL
): HtzHostname {
  const { hostname } = breakUrl(url);

  const normalizedHostname = normalizeHostname(hostname);

  const [, htzHostname] = hostnameRegex.exec(normalizedHostname) ?? [];

  if (!htzHostname) {
    // TODO: should fix typings, praedictaUri is not in the app config typings, only graphql-server
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (config.get('praedictaUri' as any)?.includes(normalizedHostname)) {
      return 'haaretz.co.il';
    }

    throw new Error(`Something went wrong, htz hostname of ${normalizedHostname} not found`);
  }

  return htzHostname as HtzHostname;
}
