import type { DropEmpty, NoNullableField } from '@haaretz/s-types';

const discardNullableValues = <T extends object>(
  obj: T
): Required<NoNullableField<Partial<T> & DropEmpty<T>>> => {
  const result: Partial<T> = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && !Number.isNaN(obj[key]))
      result[key] = obj[key];
  }

  return result as Required<NoNullableField<Partial<T> & DropEmpty<T>>>;
};

export default discardNullableValues;
