import * as Types from '@haaretz/s-fragments/Types';

export type HtmlNodeFragment = (
  { content: string, as: Types.HtmlNodeTags }
  & { __typename: 'HtmlNode' }
);


export type HtmlNodeFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const HtmlNodeFragmentGql = `
    fragment HtmlNodeFragment on HtmlNode {
  __typename
  as: tag
  content
}
    `;