import type { PodcastEpisodeFragment } from '@haaretz/s-fragments/PodcastEpisode';

const podcastEpisodeTypename: PodcastEpisodeFragment['__typename'] = 'PodcastEpisode';

export default function isPodcastEpisode<T extends Pick<PodcastEpisodeFragment, '__typename'>>(
  item?: T | { __typename: string } | null
): item is T {
  if (!item) return false;
  if (!(item instanceof Object)) return false;

  return item.__typename === podcastEpisodeTypename;
}
