'use client';

import { defaultUser } from '@haaretz/s-consts';
import useOnce from '@haaretz/s-use-once';
import createUser from '@haaretz/s-user-utils/createUser';
import Script from 'next/script';
import React from 'react';

import type { HtzUser } from '@haaretz/s-types';

const initialUser = { ...defaultUser };

type UserMutatorAction = 'logout' | 'login';

const UserContext = React.createContext<HtzUser | undefined>(undefined);
const UserSetter = React.createContext<
  ((action: UserMutatorAction, cb?: () => void) => void) | undefined
>(undefined);

interface UserProviderProps {
  value: HtzUser | null;

  /*
   *  HACK: This prop is used for storybook purposes only
   */
  sbOverrideValue?: never;
}

export function UserProvider({
  children,
  value,
  sbOverrideValue,
}: React.PropsWithChildren<UserProviderProps>) {
  const [user, setUser] = React.useState<HtzUser>(
    // istanbul ignore next
    typeof window === 'undefined'
      ? value ?? initialUser
      : sbOverrideValue || createUser(document.cookie, true)
  );

  const userSetter = React.useCallback((action: UserMutatorAction, cb?: () => void) => {
    if (action === 'logout') {
      setUser(initialUser);
    } else if (action === 'login') {
      const clientUser = createUser(document.cookie, true);

      const isLoggedIn = !!clientUser.userMail || !!clientUser.university;

      setUser({
        ...clientUser,
        isLoggedIn,
      });
    } else {
      console.error(`Invalid user action: ${action}`);
      return;
    }

    cb?.();
  }, []);

  return (
    <>
      <Script id="htz-user">{`
            window.__HTZ = window.__HTZ || {};

            window.__HTZ.userType = "${user.userType}";
      `}</Script>
      <UserContext.Provider value={user}>
        <UserSetter.Provider value={userSetter}>{children}</UserSetter.Provider>
      </UserContext.Provider>
    </>
  );
}

export function userDispenserFactory(cookie: string) {
  const newUser = createUser(cookie, true);

  const user: HtzUser = { ...newUser, isLoggedIn: !!newUser.userMail || !!newUser.university };

  return user;
}

type StartFrom = 'serverValue' | 'cookieValue';

/**
 *
 * @param startFrom -
 *   * Should be set to `serverValue` when `userType` has an effect on the
 *     initial render of a component, in order to prevent hydration mismatches.
 *   * Should be set to `cookieValue` when the value of `userType` is only used
 *     after an effect or inside an event handler.
 *
 */
export default function useUser(startFrom: StartFrom) {
  const context = React.useContext(UserContext);

  const [updatedStartFrom, setUpdatedStartFrom] = React.useState(startFrom);

  useOnce(() => {
    setUpdatedStartFrom('cookieValue');
  }, startFrom === 'serverValue');

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  if (updatedStartFrom === 'serverValue') {
    return defaultUser;
  }

  return context;
}

export function useSetUser() {
  const context = React.useContext(UserSetter);

  if (context === undefined) {
    throw new Error('useSetUser must be used within a UserProvider');
  }

  return context;
}

export function useUserAtom(startFrom: StartFrom) {
  return [useUser(startFrom), useSetUser()] as const;
}
