import BREAKPOINTS from '../scale/breakpoints';

import type { TypeScaleOpts, TypographyConfig } from '../types/typography';
import type { RequireOne } from '@haaretz/s-types';

/**
 * The default font size of basically any known browser is `16px`
 *
 * @public
 */
export const DEFAULT_BROWSER_FONT_SIZE = 16;

// css custom props

/**
 * The string prefixing color CSS custom props
 *
 * @public
 */
export const COLOR_PROP_PREFIX = '--c-';

/**
 * The string prefixing font-size CSS custom props
 *
 * @public
 */
export const FONT_SIZE_PROP_PREFIX = '--fz-';

/**
 * The string prefixing line-height CSS custom props
 *
 * @public
 */
export const LINE_HEIGHT_PROP_PREFIX = '--lh-';

/**
 * The selector used to scope custom props by default
 *
 * @public
 */
export const DEFAULT_CUSTOM_PROP_SELECTOR = 'root';

/**
 * The default media type of media queries
 *
 * @public
 */
export const DEFAULT_MEDIA_TYPE = 'all';

/**
 * The filename in which theme-related css is stored in for each app.
 *
 * @public
 */
export const THEME_CSS_FILENAME = 'theme.css';

/**
 * The name of a media query media type
 *
 * @public
 */
export type MediaType = 'all' | 'screen' | 'print';

/**
 * Named width breakpoints boundaries, in pixels
 *
 * @public
 */
export const widthBps = BREAKPOINTS.widths;

/**
 * Named width breakpoints inclusive boundaries.
 * Used for `(min-width)` media queries
 *
 * @public
 */
export const widthBpsInEm = BREAKPOINTS.widthsInEm;

/**
 * Named width breakpoints exclusive boundaries.
 * Used for `(max-width)` media queries
 *
 * @public
 */
export const untilWidthBpsInEm = BREAKPOINTS.untilWidthsInEm;

/**
 * Named media queries of miscellaneous features
 *
 * @public
 */
export const miscBps = BREAKPOINTS.misc;

/**
 * The inclusive value of a named breakpoint, in em units.
 * Used for `(min-width)` media queries.
 *
 * @remarks
 * This uses the keys of widthBps on widthBpsInEm
 * in order to validate that they correspond
 *
 * @public
 */
export type FromEmWidthBp = (typeof widthBpsInEm)[keyof typeof widthBps];

/**
 * The exclusive value of a named breakpoint, in em units.
 * Used for `(max-width)` media queries.
 *
 * @remarks
 * This uses the keys of widthBps on widthBpsInEm
 * in order to validate that they correspond
 *
 * @public
 */
export type UntilEmWidthBp = (typeof untilWidthBpsInEm)[keyof typeof widthBps];

/**
 * Name of a width breakpoint
 *
 * @public
 */
export type WidthBpName = keyof typeof widthBps;

/**
 * Name of a miscellaneous media query
 *
 * @public
 */
export type MiscBpName = keyof typeof miscBps;

/**
 * The media query string of a miscellaneous named media feature breakpoint
 *
 * @public
 */
export type MiscMq = (typeof miscBps)[keyof typeof miscBps];

/**
 * The media query string of a min-width media query
 *
 * @public
 */
export type FromMq = `(${'min-width'}:${FromEmWidthBp}em)`;

/**
 * The media query string of a max-width media query
 *
 * @public
 */
export type UntilMq = `(${'max-width'}:${UntilEmWidthBp}em)`;

/**
 * The media query string of a width media query
 *
 * @public
 */
export type WidthMq = FromMq | UntilMq;

/** @public */
export interface MqBaseArgs {
  /** A named width breakpoint. Creates a `(min-width)` media query */
  from?: WidthBpName;
  /** A named width breakpoint. Creates a `(max-width)` media query */
  until?: WidthBpName;
  /** A named misc media feature */
  misc?: MiscBpName;
}
/** @public */
export interface MediaTypeArg {
  /**
   * A media type
   *
   * @defaultValue `all`
   */
  type?: MediaType;
}

/**
 * The arguments for creating a media query string.
 *
 * Uses {@link @haaretz/s-types#RequireOne | RequireOne}
 * to require that at least one of `from`, `until` or `misc`
 * is passed. The `type` key is optional.
 *
 * @public
 */
export type MqArgs = RequireOne<MqBaseArgs> & MediaTypeArg;

/**
 * A media query string without the leading `@media`
 *
 * @remarks
 * For use in `matchMedia`
 *
 * @public
 */
export type NoAtRuleMediaQuery =
  | `${MediaType} and ${MiscMq | WidthMq}`
  | `${MediaType} and ${FromMq} and ${UntilMq}`
  | `${MediaType} and ${MiscMq} and ${WidthMq}`
  | `${MediaType} and ${MiscMq} and ${FromMq} and ${UntilMq}`;

/**
 * A media query string
 *
 * @public
 */
export type MediaQuery = `@media ${NoAtRuleMediaQuery}`;

/**
 * The default base {@link @haaretz/l-theme-shared#TypeScaleOpts | typographic config}
 * for the default breakpoint
 *
 * @public
 */
export const baseTypeScaleOpts: TypeScaleOpts = {
  base: 16,
  minPadding: 4,
  ratio: 2,
  rhythmUnit: 4,
  steps: 5,
} as const;

/**
 * The default {@link @haaretz/l-theme-shared#TypographyConfig | typographic config}
 * for all breakpoints
 *
 * @public
 */
export const defaultTypeConfig = {
  [DEFAULT_CUSTOM_PROP_SELECTOR]: baseTypeScaleOpts,
  xl: {
    ...baseTypeScaleOpts,
    base: 18,
  } as const,
  xxl: {
    ...baseTypeScaleOpts,
    base: 21,
  } as const,
} as const;

/**
 * The {@link @haaretz/l-theme-shared#TypographyConfig | typographic config}
 * for haaretz.co.il
 *
 * @public
 */
export const htzTypeConfig: Required<Pick<TypographyConfig, keyof typeof defaultTypeConfig>> =
  defaultTypeConfig;

/**
 * The {@link @haaretz/l-theme-shared#TypographyConfig | typographic config}
 * for haaretz.com
 *
 * @public
 */
export const hdcTypeConfig: Required<Pick<TypographyConfig, keyof typeof defaultTypeConfig>> =
  defaultTypeConfig;

/**
 * The {@link @haaretz/l-theme-shared#TypographyConfig | typographic config}
 * for TheMarker.com
 *
 * @public
 */
export const tmTypeConfig: Required<Pick<TypographyConfig, keyof typeof defaultTypeConfig>> =
  defaultTypeConfig;

//////////////
//  Border  //
//////////////

/**
 * An array of possible sides of an element
 *
 * @public
 */
export const borderSides = [
  'all',
  'inline',
  'block',
  'top',
  'bottom',
  'left',
  'right',
  'inline-start',
  'inline-end',
] as const;
/**
 * An array of possible border styles
 *
 * @public
 */
export const borderStyles = [
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
] as const;
