/**
 * Translates Key-Value string into a convenient map.
 * param str is string in format of "key<operator>value<separator>....."
 * param options is object for overriding defaults:
 * options.separator is a String or regExp that separates between each key value pairs
 * (default is ';'). options.operator is a String or regExp that separates between each key
 * and value within a pair (default is '=').
 * return a map-like object, with key-value mapping according to the passed configuration.
 */
export default function stringToMap(
  str: string,
  { separator = /;\s*/, operator = '=' }: { separator?: RegExp; operator?: string } = {}
): Record<string, string | Record<string, string>> {
  const map = Object.create(null);

  if (typeof str !== 'string') {
    throw new Error(`Parameter 'str' must be string, current value is ${str}`);
  }

  if (!(separator instanceof RegExp)) {
    throw new Error(
      `Parameter 'Separator' must be instance of RegExp, current value is ${separator}`
    );
  }

  if (typeof operator !== 'string') {
    throw new Error(`Parameter 'operator' must be string, current value is ${operator}`);
  }

  const itemsArr = str.split(separator);

  itemsArr.forEach(element => {
    /* istanbul ignore else */
    if (typeof element === 'string') {
      const splitPos = element.indexOf(operator);
      const keyValue = [element.substring(0, splitPos), element.substring(splitPos + 1)];

      try {
        if (keyValue[0]) {
          map[keyValue[0]] = decodeURIComponent(keyValue[1]);
        }
      } catch (error) {
        console.error((error as Error).message);
        // Do nothing, malformed URI
      }
    }
  });

  return map;
}
