import * as Types from '@haaretz/s-fragments/Types';

import { ImageFragmentGql } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragmentGql } from '@haaretz/s-fragments/ImageCrop';
import { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';
export type SpecialSaleDataFragment = (
  { exclusive: string, title?: string | null, image?: (
    ImageFragment
    & { __typename: 'htz_image_Image' }
  ) | null }
  & { __typename: 'SpecialSaleData' }
);


export type SpecialSaleDataFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const SpecialSaleDataFragmentGql = `
    fragment SpecialSaleDataFragment on SpecialSaleData {
  __typename
  exclusive
  image {
    ...ImageFragment
  }
  title
}
    `;