import type { Tuple2Union } from '@haaretz/s-types';

const discardFields = <T extends Record<string | number | symbol, unknown>, P extends (keyof T)[]>(
  object: T,
  discard: P
): Omit<T, Tuple2Union<P>> => {
  const result = {} as Partial<T>;

  for (const key in object) {
    if (!discard.includes(key)) {
      result[key] = object[key];
    }
  }

  return result as Omit<T, Tuple2Union<P>>;
};

export default discardFields;
