'use client';

import { getMatchMediaString } from '@haaretz/l-theme-shared/lib/getMqString';
import React from 'react';

import type { PlatformType } from '@haaretz/s-types';

const PlatformContext = React.createContext<PlatformType | undefined>(undefined);

const isClient = typeof window !== 'undefined';

export function PlatformProvider({
  children,
  value: defaultPlatform,
}: React.PropsWithChildren<{ value: PlatformType }>) {
  const [platform, setPlatform] = React.useState<PlatformType>(defaultPlatform);

  React.useEffect(() => {
    let changePlatform: (evt: MediaQueryListEvent) => void;

    function setPlatformByMatches(matches: boolean) {
      if (matches) {
        setPlatform('mobile');
      } else {
        setPlatform('desktop');
      }
    }
    if (isClient && platform === 'app') {
      return () => undefined;
    }
    if (isClient && platform !== 'app') {
      const matcher = window.matchMedia(getMatchMediaString({ until: 's' }));

      setPlatformByMatches(matcher.matches);

      changePlatform = (evt: MediaQueryListEvent) => {
        setPlatformByMatches(evt.matches);
      };

      matcher.addEventListener('change', changePlatform);
    }

    return () => {
      if (changePlatform && isClient) {
        const matcher = window.matchMedia(getMatchMediaString({ until: 's' }));
        matcher.removeEventListener('change', changePlatform);
      }
    };
  }, [platform]);

  return <PlatformContext.Provider value={platform}>{children}</PlatformContext.Provider>;
}

export default function usePlatform() {
  const context = React.useContext(PlatformContext);

  if (context === undefined) {
    throw new Error('usePlatform must be used within a PlatformProvider');
  }

  return context;
}
