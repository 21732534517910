import type { IConfig } from 'config';

export const shim = (conf: () => GlobalConfig<TEnv, 'external' | 'insideCluster'>) => ({
  /**
   * Get the dot-notation key path `property` from `config`, returning `undefined`
   * if it doesn't exist.
   */
  getSafe(property: Parameters<IConfig['get']>[0]): ReturnType<IConfig['get']> {
    const keys = Array.isArray(property) ? property : property.split('.');
    const value = conf();
    let result = undefined;

    let key: keyof typeof value;
    while (keys.length) {
      if (value === null || typeof value !== 'object') {
        result = undefined;
        break;
      }
      key = keys.shift();
      result = value[key];
    }

    return result;
  },

  /**
   * Like `getSafe`, but throw an error if the value is undefined.
   * Emulates the `get()` method from `config`.
   */
  get(property: Parameters<IConfig['get']>[0]): ReturnType<IConfig['get']> {
    const keyPath = Array.isArray(property) ? property.join('.') : property;
    const value = this.getSafe(property);
    if (value === undefined) {
      throw new Error(`Configuration property "${keyPath}" is not defined`);
    }
    return value;
  },

  /**
   * Emulates the `has()` method from `config`.
   */
  has(property: Parameters<IConfig['has']>[0]): ReturnType<IConfig['has']> {
    if (property === null || property === undefined) {
      return false;
    }
    return this.getSafe(property) !== undefined;
  },
});

export type ConfigShimType = ReturnType<typeof shim>;
