import getHtzHostname from '../getHtzHostname';

import type {
  HtzHostname,
  HtzHostnameWithSubdomain,
  HtzExternalURL,
  Env,
  SubDomains,
  HtzExternalUrlPath,
  HtzServiceExternalURL,
} from '@haaretz/s-types';

type ParamType =
  | HtzExternalURL<Env, HtzExternalUrlPath, SubDomains<Env>>
  | HtzServiceExternalURL<Env, HtzExternalUrlPath>
  | HtzHostname
  | HtzHostnameWithSubdomain
  | URL;
type ReturnType<T> = T extends URL ? URL : T;

export default function switchToDomain<T extends ParamType>(
  url: T,
  replaceTo: ParamType
): ReturnType<T> {
  const htzHostname = getHtzHostname(replaceTo);

  const hostname = getHtzHostname(url);
  if (typeof url === 'string') {
    const result = url.replace(hostname, htzHostname);

    return result as ReturnType<T>;
  }

  const result = url.hostname.replace(hostname, htzHostname);

  url.hostname = result;

  return url as ReturnType<T>;
}
