import getRecommendationType from './getRecommendationType';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';

export default function getBiData(biData: BiDataOverrides): BiDataOverrides {
  const result = { ...biData };

  if (result.recommendation_type) {
    const recommendation_type = getRecommendationType(biData.recommendation_type);

    if (recommendation_type) {
      result.recommendation_type = recommendation_type;
      result.additional_info ||= {};

      result.additional_info.origin = recommendation_type;
    }
  }

  return result;
}
