import decodeUnicodeBase64 from '@haaretz/s-common-utils/decodeUnicodeBase64';

import stringToMap from '../stringToMap/stringToMap';

import type { CircularObjectType } from '@haaretz/s-types';

// List of cookies that should be converted into JSON objects.
// Each element is of type:
// [ cookie-name,  cookieToMap-key, keep-raw-cookie? ]
const OBJECT_COOKIES: [string, string, boolean, boolean][] = [
  ['user_details', 'userDetails', true, true],
  ['promotions-response-message', 'ppResponseMessage', false, false],
];

let cookieAsMap: CircularObjectType<string, string | undefined>; // Memoization of cookie parsing

/**
 * Converts a cookie string to a Map-like plain object
 * param forceRefresh - flag to force re-creation of the cookie map.
 * param cookieVal - a cookie value to parse, defaults to document.cookie
 * return the cookie string represented as a key-value pairs
 */
export default function cookieToMap(cookieVal: string, forceRefresh = false) {
  if (!cookieAsMap || forceRefresh) {
    cookieAsMap = stringToMap(cookieVal, { separator: /;\s?/ });

    /* istanbul ignore else */
    for (const cookie of OBJECT_COOKIES) {
      const cookieName = cookie[0];
      const mapKey = cookie[1];
      const shouldKeepRawValue = !!cookie[2];
      const shouldDecodeBase64 = !!cookie[3];
      const currentCookieValue = cookieAsMap[cookieName];

      if (typeof currentCookieValue === 'string') {
        try {
          cookieAsMap[mapKey] = JSON.parse(
            shouldDecodeBase64 ? decodeUnicodeBase64(currentCookieValue) : currentCookieValue
          );
          if (!shouldKeepRawValue) delete cookieAsMap[cookieName];
        } catch (error) {
          /* istanbul ignore next */
          console.error((error as Error).message);
        }
      }
    }
  }

  return cookieAsMap;
}
