import type { HtzUser } from '@haaretz/s-types';

export default function isValidSsoMap(ssoMap?: Pick<HtzUser, 'userMail' | 'university'>) {
  const typeValidity =
    ssoMap &&
    typeof ssoMap !== 'undefined' &&
    typeof ssoMap === 'object' &&
    (typeof ssoMap.userMail === 'string' || ssoMap.university === true);

  const isValidRegularSso =
    typeValidity && typeof ssoMap.userMail === 'string' && /\S+@\S+\.\S+/.test(ssoMap.userMail);

  const isValidUniversitySso = !isValidRegularSso && typeValidity && ssoMap.university === true;

  const valueValidity = isValidRegularSso || isValidUniversitySso;

  return !!valueValidity;
}
