import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import { useRouter } from 'next/router';
import React from 'react';

import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { ppQueryVariables } from '@haaretz/s-use-pp-data-variables';

export default function usePpAbTest() {
  const router = useRouter();
  const abTestParam = router.query.forceAbTest;

  const excludedVariables: (keyof Required<ppQueryVariables>)[] = React.useMemo(
    () => ['selectedOfferId'],
    []
  );

  const variables = usePpDataVariables(excludedVariables);

  const { data } = usePurchasePagePersonalQuery(variables, { enabled: false });

  // NOTE: This is used only for testing purposes
  if (abTestParam && typeof abTestParam === 'string') {
    try {
      const decodedAbTest = atob(abTestParam);
      return JSON.parse(decodedAbTest) as PageDataFragment['abTest'];
    } catch (e) {
      console.error(e);
    }
  }

  return data?.PurchasePage?.pageData?.abTest;
}
