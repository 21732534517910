'use client';

import useUser from '@haaretz/s-atoms/user';
import { PP_PREV_STEPS_KEY } from '@haaretz/s-consts';
import getCurrentStepName from '@haaretz/s-pp-routing-utils/getCurrentStepName';
import isGqlPpStep from '@haaretz/s-pp-routing-utils/isGqlPpStep';
import { useRouter } from 'next/router';
import React, { PropsWithChildren } from 'react';

import type { PurchasePageSteps } from '@haaretz/s-fragments/Types';
import type { NonGqlPpSteps } from '@haaretz/s-types';

type PpSteps = PurchasePageSteps | NonGqlPpSteps;

interface PPOffsetStepsActionsContextProps {
  pushStep: (step: PurchasePageSteps) => void;
  clearSteps: () => void;
}

interface PPPrevStepsActionsContextProps {
  pushPrevStep: (step: PpSteps) => void;
  pushPrevSteps: (steps: PpSteps[]) => void;
  getPrevSteps: () => PurchasePageSteps[];
}

const OffsetStepsContext = React.createContext<PurchasePageSteps[] | undefined>(undefined);
const PrevStepsContext = React.createContext<PurchasePageSteps[] | undefined>(undefined);
const UdapteOffsetStepsContext = React.createContext<PPOffsetStepsActionsContextProps | undefined>(
  undefined
);
const UdaptePrevStepsContext = React.createContext<PPPrevStepsActionsContextProps | undefined>(
  undefined
);

const loginPageSteps: PurchasePageSteps[] = ['login', 'updateDetails'];

export default function PPOffsetStepsStoreContextProvider({
  children,
  initialState,
}: PropsWithChildren<{ initialState?: PurchasePageSteps[] }>) {
  const [prevSteps, setPrevSteps] = React.useState<PurchasePageSteps[]>([]);
  const [offsetSteps, setOffsetSteps] = React.useState<PurchasePageSteps[]>(initialState || []);
  const isFilledRef = React.useRef<boolean>(initialState?.includes('login') || false);
  const user = useUser('cookieValue');
  const router = useRouter();

  const offsetStepsActions = React.useMemo(() => {
    const pushStep = (step: PurchasePageSteps) => {
      /* istanbul ignore else */
      if (!isFilledRef.current) {
        const isLoggedInOnOffersPage = user?.userMail && step === 'offers';

        /* istanbul ignore else */
        if (!user?.userMail || loginPageSteps.includes(step) || isLoggedInOnOffersPage) {
          if (loginPageSteps.includes(step) || isLoggedInOnOffersPage) {
            isFilledRef.current = true;
          }

          setOffsetSteps(prevState => [...prevState, step]);
        }
      }
    };

    const clearSteps = () => {
      setOffsetSteps([]);

      isFilledRef.current = false;
    };

    return {
      pushStep,
      clearSteps,
    };
  }, [user.userMail]);

  const prevStepsActions = React.useMemo(() => {
    const // This function adds a gql step to the prevSteps array
      pushPrevStep = (step: PpSteps) => {
        // If the step is a gql step
        if (isGqlPpStep(step)) {
          // Add the step to the prevSteps array
          setPrevSteps(prevState => {
            if (!prevState.includes(step)) {
              return [...prevState, step];
            }

            return prevState;
          });
        }
      };

    const pushPrevSteps = (steps: PpSteps[]) => {
      if (prevSteps.length <= 1 && steps.length) {
        setPrevSteps(prevState => [
          ...steps.filter(isGqlPpStep).filter(step => !prevState.includes(step)),
          ...prevState,
        ]);
      }
    };

    const getPrevSteps = () => {
      return prevSteps;
    };

    return {
      pushPrevStep,
      pushPrevSteps,
      getPrevSteps,
    };
  }, [prevSteps]);

  const resultOffsetSteps = React.useMemo(() => {
    const currentStepName = getCurrentStepName(router.pathname);
    return isFilledRef.current && currentStepName !== 'offers' && currentStepName !== 'coupon'
      ? offsetSteps
      : [];
  }, [offsetSteps, router.pathname]);

  return (
    <OffsetStepsContext.Provider value={resultOffsetSteps}>
      <PrevStepsContext.Provider value={prevSteps}>
        <UdapteOffsetStepsContext.Provider value={offsetStepsActions}>
          <UdaptePrevStepsContext.Provider value={prevStepsActions}>
            {children}
          </UdaptePrevStepsContext.Provider>
        </UdapteOffsetStepsContext.Provider>
      </PrevStepsContext.Provider>
    </OffsetStepsContext.Provider>
  );
}

////////////
//  Hook  //
////////////

export function usePPOffsetStepsStore() {
  const context = React.useContext(OffsetStepsContext);

  if (!context) {
    throw new Error(
      'usePPOffsetStepsStore must be used within a PPOffsetStepsStoreContextProvider'
    );
  }
  return context;
}

export function usePPPrevStepsStore() {
  const context = React.useContext(PrevStepsContext);

  if (!context) {
    throw new Error('usePPPrevStepsStore must be used within a PPOffsetStepsStoreContextProvider');
  }
  return context;
}

export function usePPOffsetStepsStoreActions() {
  const context = React.useContext(UdapteOffsetStepsContext);

  if (!context) {
    throw new Error(
      'usePPOffsetStepsStoreActions must be used within a PPOffsetStepsStoreContextProvider'
    );
  }

  return context;
}

export function usePPPrevStepsStoreActions() {
  const context = React.useContext(UdaptePrevStepsContext);

  if (!context) {
    throw new Error(
      'usePPPrevStepsStoreActions must be used within a PPOffsetStepsStoreContextProvider'
    );
  }

  return context;
}

/* istanbul ignore next */
function usePpPrevStepInitialization() {
  const prevSteps = usePPPrevStepsStore();
  const { pushPrevSteps } = usePPPrevStepsStoreActions();
  const router = useRouter();

  React.useEffect(() => {
    const prevPagesString = sessionStorage.getItem(PP_PREV_STEPS_KEY);
    if (prevPagesString) {
      try {
        const prevPages = JSON.parse(prevPagesString) as PurchasePageSteps[];

        if (Array.isArray(prevPages)) {
          const stepName = getCurrentStepName(router.pathname);
          pushPrevSteps([...prevPages, stepName]);
        }

        sessionStorage.removeItem(PP_PREV_STEPS_KEY);
      } catch (error) {
        console.error((error as Error).message);
      }
    }
  }, [prevSteps, pushPrevSteps, router.pathname]);
}

export { usePpPrevStepInitialization };
