import * as Types from '@haaretz/s-fragments/Types';

export type AbTestDataFragment = (
  { testGroup: Types.AbTestGroup, testGroupLabel: string, testName: string }
  & { __typename: 'AbTestData' }
);


export type AbTestDataFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const AbTestDataFragmentGql = `
    fragment AbTestDataFragment on AbTestData {
  testGroup
  testGroupLabel
  testName
  __typename
}
    `;