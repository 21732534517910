'use client';

import React from 'react';

const CountryCodeContext = React.createContext<string | null | undefined>(undefined);

export function CountryCodeProvider({
  children,
  value,
}: React.PropsWithChildren<{ value: string }>) {
  return <CountryCodeContext.Provider value={value}>{children}</CountryCodeContext.Provider>;
}

export default function useCountryCode() {
  const context = React.useContext(CountryCodeContext);

  if (context === undefined) {
    throw new Error('useCountryCode must be used within a CountryCodeProvider');
  }

  return context;
}
